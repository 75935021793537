import React from "react";

import Layout from "../components/layout";
import ContactForm from "../components/contact-us/contact-form";

const ContactUs = () => {
  return (
    <Layout>
      <ContactForm />
    </Layout>
  );
};

export default ContactUs;
